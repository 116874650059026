<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="模板标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'sms_title',
          {
            rules: [{ required: true, message: '模板标题不能为空' }],
            initialValue: smsInfo.sms_title
          },
        ]" placeholder="" />
      </a-form-item>
      <a-form-item label="模板内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="5" placeholder="请输入管理员描述信息" v-decorator="[
          'sms_content',
          {
            rules: [{ required: true, message: '模板内容不能为空' }],
            initialValue: smsInfo.sms_content
          }
        ]" />
      </a-form-item>
      <a-divider>可替换参数显示</a-divider>
      <a-divider style="margin: 16px 0" orientation="left">分类变量</a-divider>
      <detail-list size="small" :col="1" class="detail-layout">
        <detail-list-item v-for="(items, keys) in business_params" :key="keys" :term="keys"><span
            v-for="(item, key) in items" :key="key">
            <font :style="fontStyle">{{ item.title }}</font> : {{ item.var }}；
          </span></detail-list-item>
      </detail-list>
      <a-divider style="margin: 16px 0" orientation="left">公共变量</a-divider>
      <detail-list size="small" :col="4" class="detail-layout">
        <detail-list-item v-for="(item, keys) in common_params" :key="keys" :term="item.title">{{ item.var }}
        </detail-list-item>
      </detail-list>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { smsTemplate, smsEditTemplate, smsAddTemplate, SmsReplaceVars } from '@/api/sms'
import DetailList from '@/components/tools/DetailList'

const DetailListItem = DetailList.Item

export default {
  name: 'EditSmsTemplate',
  components: {
    DetailList,
    DetailListItem
  },
  data() {
    return {
      description: '短信模板编辑添加',
      value: 1,
      fontStyle: {
        fontWeight: 1000
      },
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      customLabelCol: {
        lg: { span: 4 },
        sm: { span: 7 }
      },
      customWrapperCol: {
        lg: { span: 18 },
        sm: { span: 17 }
      },
      queryData: {},
      checkPass: true,
      smsInfo: {},
      business_params: {},
      common_params: {},
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      submitLoading: false
    }
  },
  created() {
    const id = this.$route.query.id
    this.queryData.id = id
    if (id !== undefined) {
      this.pageType = 'edit'
      this.loadInfo(id)
    }
    this.loadReplaceVars()
  },
  methods: {
    loadInfo(id) {
      new Promise((resolve, reject) => {
        smsTemplate({ 'template_id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.smsInfo = res.data
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回
        setTimeout(() => {
          this.$router.back(-1)
        }, 2000)
      })
    },
    loadReplaceVars() {
      new Promise((resolve, reject) => {
        SmsReplaceVars().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.business_params = res.data.business_params
        this.common_params = res.data.common_params
      })
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.template_id = this.queryData.id
              smsEditTemplate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              smsAddTemplate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
<style lang="less">
.detail-layout {
  margin-left: 44px;
}

.mobile {
  .detail-layout {
    margin-left: unset;
  }

  .status-list {
    text-align: left;
  }
}
</style>
