var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"模板标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'sms_title',
        {
          rules: [{ required: true, message: '模板标题不能为空' }],
          initialValue: _vm.smsInfo.sms_title
        },
      ]),expression:"[\n        'sms_title',\n        {\n          rules: [{ required: true, message: '模板标题不能为空' }],\n          initialValue: smsInfo.sms_title\n        },\n      ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"模板内容","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'sms_content',
        {
          rules: [{ required: true, message: '模板内容不能为空' }],
          initialValue: _vm.smsInfo.sms_content
        }
      ]),expression:"[\n        'sms_content',\n        {\n          rules: [{ required: true, message: '模板内容不能为空' }],\n          initialValue: smsInfo.sms_content\n        }\n      ]"}],attrs:{"rows":"5","placeholder":"请输入管理员描述信息"}})],1),_c('a-divider',[_vm._v("可替换参数显示")]),_c('a-divider',{staticStyle:{"margin":"16px 0"},attrs:{"orientation":"left"}},[_vm._v("分类变量")]),_c('detail-list',{staticClass:"detail-layout",attrs:{"size":"small","col":1}},_vm._l((_vm.business_params),function(items,keys){return _c('detail-list-item',{key:keys,attrs:{"term":keys}},_vm._l((items),function(item,key){return _c('span',{key:key},[_c('font',{style:(_vm.fontStyle)},[_vm._v(_vm._s(item.title))]),_vm._v(" : "+_vm._s(item.var)+"； ")],1)}),0)}),1),_c('a-divider',{staticStyle:{"margin":"16px 0"},attrs:{"orientation":"left"}},[_vm._v("公共变量")]),_c('detail-list',{staticClass:"detail-layout",attrs:{"size":"small","col":4}},_vm._l((_vm.common_params),function(item,keys){return _c('detail-list-item',{key:keys,attrs:{"term":item.title}},[_vm._v(_vm._s(item.var)+" ")])}),1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.routerback}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }